import { useInvoicePayment } from "context/invoice-payment-context"
import React, { useCallback, useEffect, useState } from "react"
import { getInvoiceDownloadFile, getInvoicesForBuyAndShip } from "utils/buy-order-client"
import { getInvoicesForShipment } from "utils/shipment-product-client"
import { Button, Col, Form, Input, Row, Select, Table } from "antd"
import { PageHeader } from '@ant-design/pro-layout';
import { clearQuerySearchParamsByKeys, getQueryParams, insertUrlParams, toastErrors } from "utils/helpers"
import { DateTime, InvoicePayModal, StatusTag } from "components"
import { BUY_AND_SHIP } from "consts/app-constants"
import BuyInvoice from "./BuyInvoice"
import ShipmentInvoice from "./ShipmentInvoice"
import { getWebsites } from "utils/oauth-client";

const { Column } = Table

const INITIAL_FILTERS = {
  website_id: undefined
}

const InvoiceList = Props => {
  const { invoiceType } = Props
  const [form] = Form.useForm()
  const {
    fetchGatewayList,
    fetchWalletBalance,
    setSelectedInvoices,
    setInvoiceType
  } = useInvoicePayment()

  const [response, setResponse] = useState({ data: [], meta: null })
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [filters, setFilters] = useState(INITIAL_FILTERS)
  const [isLoading, setIsLoading] = useState(false)
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [isServiceGetCalled, setIsServiceGetCalled] = useState(false)
  const [websiteList, setWebsiteList] = useState([]);
  const [isWebsiteFetching, setIsWebsiteFetching] = useState(false);
  const queryParams = getQueryParams()

  const fetchInvoices = React.useCallback(
    (page, filter, pageSize) => {
      setIsLoading(true)
      let client = null
      if (invoiceType === BUY_AND_SHIP) {
        client = getInvoicesForBuyAndShip(page, filter, pageSize)
      } else {
        client = getInvoicesForShipment(page, filter, pageSize)
      }

      client.then(res => {
        if (res && res.data && res.data.data) {
          setResponse({
            data: res.data.data,
            meta: res.data.meta
          })
        }
        setIsLoading(false)
      })
        .catch(({ response }) => {
          if (response?.data) {
            toastErrors(response.data);
          }
        }).finally(() => setIsLoading(false))
    },
    [invoiceType],
  )

  const fetchWebsites = useCallback(
    () => {
      setIsWebsiteFetching(true)
      getWebsites()
        .then(res => {
          setWebsiteList(res.data)
        }).catch(({ response }) => {
          if (response?.data) {
            toastErrors(response.data);
          }
        }).finally(() => setIsWebsiteFetching(false))
    },
    [],
  )

  const fetchDownloadFile = React.useCallback(
    (param, invoiceID) => {
      setIsLoading(true)

      getInvoiceDownloadFile(param, invoiceID)
        .then(res => {
          if (res && res.data && res.data.download_link) {
            let downloadLink = res.data.download_link

            const link = document.createElement('a');
            link.href = downloadLink;
            link.target = '_blank';
            link.download = 'invoice.pdf';
            link.click();
          }
          setIsLoading(false)
        })
        .catch(({ response }) => {
          if (response?.data) {
            toastErrors(response.data);
          }
        }).finally(() => setIsLoading(false))
    },
    [invoiceType],
  )


  useEffect(() => {
    if (queryParams['order']) {
      form.setFieldsValue({ "order": queryParams["order"] });
    }
    if (queryParams['website_id']) {
      form.setFieldsValue({ "website_id": queryParams["website_id"] });
    }
  }, [queryParams, form])

  useEffect(() => {
    if (!isServiceGetCalled) {
      setSelectedRowKeys([])
      fetchInvoices()
      fetchGatewayList()
      fetchWalletBalance()
      setInvoiceType(invoiceType)
      fetchWebsites();
      setIsServiceGetCalled(true)
    }
  }, [invoiceType, fetchInvoices, fetchGatewayList, fetchWalletBalance, fetchWebsites, isServiceGetCalled, setInvoiceType])

  const handleFilterChange = (field, value) => {
    clearQuerySearchParamsByKeys([field])
    let fieldValue = !value ? undefined : value
    if (field === "date" && value[0] === "") {
      fieldValue = undefined
    }
    setFilters({
      ...filters,
      [field]: fieldValue
    })
  }

  const handleClear = () => {
    form.resetFields()
    setFilters(INITIAL_FILTERS)
    clearQuerySearchParamsByKeys(["order", "website_id"])
  }

  const handleFilterSubmit = () => {
    let validFilters = []
    for (let [key, value] of Object.entries(filters)) {
      if (value !== undefined) {
        validFilters.push({ key: key, value: value })
      }
    }
    insertUrlParams(validFilters)
    fetchInvoices(1, filters)
  }

  const handlePageChange = (page, pageSize) => {
    insertUrlParams([
      {
        key: "page_index",
        value: page
      }
    ])
    fetchInvoices(page, filters, pageSize)
  }

  const onShowSizeChange = (current, size) => {
    insertUrlParams([
      {
        key: "per_page",
        value: size
      }
    ])
    fetchInvoices(current, filters, size)
  }

  const paginationConfig = response && response?.meta ? {
    total: response.meta.total,
    current: response.meta.current_page,
    onChange: handlePageChange,
    onShowSizeChange: onShowSizeChange,
    pageSize: response.meta.per_page,
    showSizeChanger: true,
    position: ["none", "bottomLeft"]
  } : false

  useEffect(() => {
    if (queryParams['order']) {
      let filter = { order: queryParams["order"] }
      fetchInvoices(1, filter, 20)
    }
  }, [window.location.search])

  return (
    <PageHeader
      ghost={false}
    >
      <Row>
        <Col span={24}>
          <Form
            form={form}
            labelCol={{ span: 12 }}
            layout="vertical"
            onFinish={handleFilterSubmit}
          >
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                <Form.Item label="Website" name="website_id">
                  <Select
                    placeholder="Please select website"
                    onChange={(value) => handleFilterChange('website_id', value)}
                    loading={isWebsiteFetching}
                  >
                    {
                      websiteList && websiteList.map(d => <Select.Option value={d.id} key={d.id}>{d.name}</Select.Option>)
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                <Form.Item label="Order number" name="order">
                  <Input
                    placeholder="Enter order number"
                    onChange={(e) => handleFilterChange("order", e.target.value)}
                  />
                </Form.Item>
              </Col>
              {
                invoiceType === BUY_AND_SHIP ?
                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                  <Form.Item label="Buy Product status" name="product_status">
                    <Select
                      placeholder="Select buy product status"
                      onChange={(value) =>
                        handleFilterChange("product_status", value)
                      }
                    >
                      <Select.Option value="buy-pending">Pending</Select.Option>
                      <Select.Option value="buy-approved">Approved</Select.Option>
                      <Select.Option value="buy-processing">Processing</Select.Option>
                      <Select.Option value="buy-purchased">Purchased</Select.Option>
                      <Select.Option value="buy-not-purchased">Not Purchased</Select.Option>
                      <Select.Option value="buy-canceled">Canceled</Select.Option>
                      <Select.Option value="handover-to-shipping">Handover To Shipping</Select.Option>
                      <Select.Option value="arrived-at-warehouse">Arrived At Warehouse</Select.Option>
                      <Select.Option value="preparing-for-transport">Preparing For Transport</Select.Option>
                      <Select.Option value="handover-to-airline">Handover To Airline</Select.Option>
                      <Select.Option value="arrived-at-destination-airport">Arrived At Destination Airport</Select.Option>
                      <Select.Option value="received-by-moveon">Received By MoveOn</Select.Option>
                      <Select.Option value="customs-released">Custom Released</Select.Option>
                    </Select>
                  </Form.Item>
                </Col> : null
              }
            </Row>
            <Row className="mobile-left-margin">
              <Col span={24}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">Search</Button>
                  <Button onClick={handleClear} style={{ marginLeft: "15px" }}>Clear</Button>
                  <Button
                    onClick={() => fetchInvoices(invoiceType)}
                    type="primary"
                    danger
                    loading={isLoading}
                    style={{
                      float: "right",
                      marginRight: "3%"
                    }}
                  >Reload</Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          {
            selectedRowKeys.length > 0 && (
              <Row>
                <Col flex="81px" style={{ marginBottom: 16 }}>
                  <Button
                    onClick={() => setShowPaymentModal(true)}
                    type={"primary"}
                  >
                    Pay {selectedRowKeys.length} Invoices
                  </Button>
                </Col>
              </Row>
            )
          }

          {
            invoiceType === BUY_AND_SHIP ?
              <Table
                dataSource={response.data}
                loading={isLoading}
                pagination={paginationConfig}
                rowKey="id"
                defaultExpandAllRows={true}
                scroll={{
                  x: true
                }}
                rowSelection={{
                  selectedRowKeys,
                  onChange: (selectedRowKeys, selectedRows) => {
                    setSelectedRowKeys(selectedRowKeys)
                    setSelectedInvoices(selectedRows)
                  }
                }}
                expandedRowRender={(record) => {
                  return (
                    <BuyInvoice
                      invoice={record}
                    />
                  )
                }}
              >
                <Column
                  title="Invoice"
                  dataIndex="code"
                  key="code"
                  align={"center"}
                  render={(code, record) => {
                    return "Buying Invoice #" + code
                  }}
                />
                <Column
                  title="Status"
                  dataIndex="status"
                  key="status"
                  align={"center"}
                  style={{ width: 200 }}
                  render={(status, record) => (
                    <StatusTag slug={status} text={record.status_label} />
                  )}
                />
                <Column
                  title="Total"
                  dataIndex="total"
                  key="total"
                  align={"center"}
                  style={{ width: 200 }}
                  render={(total, record) => {
                    return total ? total.toFixed(2) : 0
                  }}
                />
                <Column
                  title="Paid amount"
                  dataIndex="paid_amount"
                  key="paid_amount"
                  align={"center"}
                  style={{ width: 200 }}
                  render={(paid_amount, record) => {
                    return paid_amount ? parseFloat(paid_amount).toFixed(2) : 0
                  }}
                />
                <Column
                  title="Created At"
                  dataIndex="created_at"
                  key="created-at"
                  align={"center"}
                  responsive={["xl"]}
                  render={created_at => (
                    <DateTime date={created_at} />
                  )}
                />
                <Column
                  title="Action"
                  dataIndex="id"
                  key="id"
                  align={"center"}
                  responsive={["xl"]}
                  render={(id, data) => {
                    return (
                      <>
                        {data.status !== 'paid' && (
                          <Button
                            onClick={() => {
                              setSelectedRowKeys([id]);
                              setShowPaymentModal(true);
                              setSelectedInvoices([data]);
                            }}
                            type={"primary"}
                            style={{ marginLeft: 8 }}
                          >
                            Pay Invoice
                          </Button>
                        )}
                        <Button
                          onClick={() => {
                            fetchDownloadFile("buy-and-ship", id);
                          }}
                          type={"default"}
                        >
                          Download
                        </Button>
                      </>
                    );
                  }}
                />
              </Table>
              :
              <Table
                dataSource={response.data}
                loading={isLoading}
                rowKey="id"
                defaultExpandAllRows={true}
                pagination={paginationConfig}
                scroll={{
                  x: true
                }}
                rowSelection={{
                  selectedRowKeys,
                  onChange: (selectedRowKeys, selectedRows) => {
                    setSelectedRowKeys(selectedRowKeys)
                    setSelectedInvoices(selectedRows)
                  }
                }}
                expandedRowRender={(record) => {
                  return (
                    <ShipmentInvoice
                      invoice={record}
                    />
                  )
                }}
              >
                <Column
                  title="Invoice"
                  dataIndex="invoice_code"
                  key="code"
                  align={"center"}
                  render={(code, record) => {
                    return "Shipping Invoice #" + code
                  }}
                />
                <Column
                  title="Status"
                  dataIndex="status"
                  key="status"
                  align={"center"}
                  style={{ width: 200 }}
                  render={(status, record) => (
                    record.status &&
                    <StatusTag slug={record.status.slug} text={record.status.name} />
                  )}
                />
                <Column
                  title="Total"
                  dataIndex="amount_total"
                  key="total"
                  align={"center"}
                  style={{ width: 200 }}
                  render={(total, record) => {
                    return total ? total.toFixed(2) : 0
                  }}
                />
                <Column
                  title="Paid amount"
                  dataIndex="amount_paid"
                  key="paid_amount"
                  align={"center"}
                  style={{ width: 200 }}
                  render={(paid_amount, record) => {
                    return paid_amount ? parseFloat(paid_amount).toFixed(2) : 0
                  }}
                />
                <Column
                  title="Created At"
                  dataIndex="created_at"
                  key="created-at"
                  align={"center"}
                  responsive={["xl"]}
                  render={created_at => (
                    <DateTime date={created_at} />
                  )}
                />
                <Column
                  title="Action"
                  dataIndex="id"
                  key="id"
                  align={"center"}
                  responsive={["xl"]}
                  render={(id, data) => {
                    return (
                      <>
                        {data.status && data.status.slug !== 'invoice-payment-paid' && (
                          <Button
                            onClick={() => {
                              setSelectedRowKeys([id]);
                              setShowPaymentModal(true);
                              setSelectedInvoices([data]);
                            }}
                            type={"primary"}
                          >
                            Pay Invoice
                          </Button>
                        )}
                        <Button
                          onClick={() => {
                            fetchDownloadFile("ship-for-me", id);
                          }}
                          type={"default"}
                          style={{ marginLeft: 8 }}
                        >
                          Download
                        </Button>
                      </>
                    );
                  }}
                />
              </Table>
          }

        </Col>
      </Row>
      {
        selectedRowKeys.length > 0 &&
        (
          <InvoicePayModal
            show={showPaymentModal}
            onHide={() => setShowPaymentModal(false)}
            handlePageAction={Props.handlePageAction}
          />
        )
      }
    </PageHeader>
  )
}

export default InvoiceList
