import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  Image,
  Input,
  message,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { DateTime, NewDeliveryRequest, StatusTag } from "components";
import { AddressModal, OrdersModal, RequestDetail } from "../components";
import {
  calculateShippingPrice,
  formatDateTime,
  formateDateTime,
  getQueryParams,
  insertUrlParams,
  toastErrors,
  updateSize,
} from "utils/helpers";
import { useBuyForwardingParcel } from "context/buy-forwarding-context";
import {
  getRequestedDelivery,
  getRequestedDeliveryLogs,
  getRequestedDeliveryProducts,
} from "utils/buy-order-client";
import { getWebsites } from "../../../utils/oauth-client";
import RefundForm from "../components/RefundForm";

const { Column } = Table;

// const {RangePicker} = DatePicker
const INITIAL_FILTERS = {
  order_number: undefined,
  customer_id: undefined,
  date: undefined,
};

const MyParcel = (Props) => {
  return (
    <>
      <BangladeshToYou handlePageAction={Props.handlePageAction} />
      <Divider />
      <DeliveryRequest handlePageAction={Props.handlePageAction} />
    </>
  );
};

const BangladeshToYou = (Props) => {
  const { bdToYouOrders, fetchBdToYouOrders, bdToYouLoading } =
    useBuyForwardingParcel();
  const [form] = Form.useForm();
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [selectedProducts] = useState([]);
  const [, setSelectedRows] = useState([]);
  const [newDeliveryRequestPopup, setNewDeliveryRequestPopup] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showOrdersModal, setShowOrdersModal] = useState(false);
  const [selectedCorrelatedItemId, setSelectedCorrelatedItemId] =
    useState(null);
  const [websiteList, setWebsiteList] = useState([]);
  const [isWebsiteFetching, setIsWebsiteFetching] = useState(false);

  const fetchOrders = useCallback(() => {
    let queryParams = getQueryParams();
    let page = queryParams["page_index"]
      ? parseInt(queryParams["page_index"])
      : 1;
    let per_page = queryParams["per_page"]
      ? parseInt(queryParams["per_page"])
      : 10;
    fetchBdToYouOrders(page, filters, per_page);
  }, [fetchBdToYouOrders, filters]);

  const fetchWebsites = useCallback(() => {
    setIsWebsiteFetching(true);
    getWebsites()
      .then((res) => {
        setWebsiteList(res.data);
      })
      .catch(({ response }) => {
        if (response?.data) {
          toastErrors(response.data);
        }
      })
      .finally(() => setIsWebsiteFetching(false));
  }, []);

  useEffect(() => {
    fetchOrders();
    fetchWebsites();
  }, [fetchOrders, fetchWebsites]);

  const handleFilterChange = (field, value) => {
    let fieldValue = !value ? undefined : value;
    if (field === "date" && value[0] === "") {
      fieldValue = undefined;
    }
    setFilters({
      ...filters,
      [field]: fieldValue,
    });
  };

  const handleClear = () => {
    form.resetFields();
    setFilters(INITIAL_FILTERS);
  };

  const handlePageChange = (page, pageSize) => {
    insertUrlParams([
      {
        key: "page_index",
        value: page,
      },
    ]);
    fetchBdToYouOrders(page, filters, pageSize);
  };

  const handleFilterSubmit = () => {
    let validFilters = [];
    for (let [key, value] of Object.entries(filters)) {
      if (value !== undefined) {
        validFilters.push({ key: key, value: value });
      }
    }
    insertUrlParams(validFilters);
    fetchBdToYouOrders(1, filters);
  };

  const paginationConfig =
    bdToYouOrders && bdToYouOrders?.meta
      ? {
          total: bdToYouOrders.meta.total,
          current: bdToYouOrders.meta.current_page,
          onChange: handlePageChange,
          pageSize: bdToYouOrders.meta.per_page,
          showSizeChanger: false,
          position: ["none", "bottomLeft"],
        }
      : false;

  const getBuyInvoiceStatus = (order) => {
    let buyInvoice = null;
    let is_buy_paid = false;

    if (order.buyInvoice.length !== 0) {
      buyInvoice = order.buyInvoice.find((invoice) => invoice.status);
      is_buy_paid = buyInvoice.status === "paid";
    }

    return {
      invoice: buyInvoice,
      is_paid: is_buy_paid,
    };
  };

  const getShipmentInvoiceStatus = (order) => {
    let shipmentInvoice = null;
    let is_ship_paid = false;
    if (order.invoices.length !== 0) {
      shipmentInvoice = order.invoices.find((invoice) => invoice.status);
      is_ship_paid = shipmentInvoice.status.slug === "invoice-payment-paid";
    }
    return {
      invoice: shipmentInvoice,
      is_paid: is_ship_paid,
    };
  };

  const renderNestedTable = (record, index) => {
    const items = record.buyProducts;
    const orderNumber = record.order_number;
    let isBuyPaid = false;
    let buyInvoice = getBuyInvoiceStatus(record);
    if (buyInvoice && buyInvoice.is_paid) {
      isBuyPaid = true;
    }

    let isShipPaid = false;
    let shipmentInvoice = getShipmentInvoiceStatus(record);
    if (shipmentInvoice && shipmentInvoice.is_paid) {
      isShipPaid = shipmentInvoice.is_paid;
    }

    return (
      <Table
        dataSource={items}
        pagination={false}
        rowKey="id"
        rowSelection={{
          selectedRowKeys: selectedProducts[record.id] ?? [],
          onChange: (keys) => {
            if (!selectedProducts[record.id]) {
              selectedProducts[record.id] = [];
            }
            if (!isShipPaid || !isBuyPaid) {
              message.error("Please pay your invoice first");
              return;
            }
            setSelectedRows(keys);
            selectedProducts[record.id] = keys;
          },
        }}
        scroll={{
          x: true,
        }}
      >
        <Column
          title="Image"
          dataIndex="image"
          key="image"
          align="center"
          responsive={["md"]}
          width={"10%"}
          render={(image, record) =>
            record && record.image != null ? (
              <Image
                shape="square"
                width={100}
                src={
                  record?.store?.storeId === 1
                    ? updateSize(record.image, 100)
                    : updateSize(record.image, 800)
                }
              />
            ) : (
              <Avatar shape="square" size={64} src={""} />
            )
          }
        />

        <Column
          title="Product"
          dataIndex="product_number"
          key="product_number"
          width={"25%"}
          render={(product_number, record) => {
            return (
              <>
                <Row>
                  <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
                    <div className="buy_title">
                      <a href={record.link} target="_blank" rel="noreferrer">
                        <Typography.Text
                          ellipsis={{ tooltip: record.title }}
                          style={{ width: "150px" }}
                        >
                          {record.title}
                        </Typography.Text>
                      </a>
                    </div>
                    <div className="productNumber">
                      <span>Product ID: {product_number}</span>
                    </div>
                    <div className="orderNumber">
                      <span>Order ID: {orderNumber}</span>
                    </div>
                    <div className="productStatus">
                      <span>Status : </span>{" "}
                      <StatusTag
                        slug={record.status}
                        text={record.status_label}
                      />
                    </div>
                    <div
                      className="invoiceStatus"
                      style={{ marginTop: "1%", marginBottom: "1%" }}
                    >
                      <span>Vendor : </span>
                      <Tag color="#87d068">{record?.vendor}</Tag>
                    </div>
                    <div className="note">
                      <span>Note: </span>
                      <span className="text_color">{record.note}</span>
                    </div>
                  </Col>
                </Row>
              </>
            );
          }}
        />

        <Column
          title="Product Meta"
          dataIndex="id"
          key="detail"
          width={"35%"}
          align="center"
          render={(_, record) => {
            const totalQuantity = record?.itemMetas?.reduce((acc, value) => {
              if (value.qty === "") {
                value = 0;
              } else {
                value = parseInt(value.qty);
              }
              return acc + value;
            }, 0);

            const totalPriceBdt = record?.itemMetas?.reduce((acc, value) => {
              if (value.productPrice === "") {
                value = 0;
              } else {
                value = parseFloat(value.productPrice * value.qty);
              }
              return acc + value;
            }, 0);

            return (
              <>
                <Table
                  dataSource={record.itemMetas}
                  bordered={false}
                  pagination={false}
                  size="small"
                  scroll={{ y: 150 }}
                  summary={() => {
                    let totalCharges = "";
                    if (record?.charges) {
                      totalCharges = record?.charges.reduce((acc, value) => {
                        return acc + value.amount;
                      }, 0);
                    }
                    return (
                      <>
                        {record?.charges?.map((metaCharge, index) => {
                          return (
                            <Table.Summary.Row key={index + "charges"}>
                              <Table.Summary.Cell colSpan={3}>
                                {metaCharge.type_label}
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align={"center"}>
                                <span>{metaCharge.amount.toFixed(2)}</span>
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          );
                        })}

                        <Table.Summary.Row>
                          <Table.Summary.Cell align={"center"}>
                            Total
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align={"center"}>
                            <span>{totalQuantity}</span>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align={"center"}>
                            &nbsp;
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align={"center"}>
                            <span>
                              {totalPriceBdt
                                ? (
                                    (totalCharges ? totalCharges : null) +
                                    totalPriceBdt
                                  ).toFixed(2)
                                : null}
                            </span>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                >
                  <Column
                    title="Meta"
                    dataIndex="itemMetas"
                    key="meta"
                    align={"center"}
                    width={"20%"}
                    render={(meta, record) => {
                      return record.meta.map(
                        (itemVariation, itemVariationID) => {
                          return (
                            <div
                              className="warehouse-address ml-lg-4"
                              key={`varItem-${itemVariationID}`}
                            >
                              <div>
                                {itemVariation.title}:{" "}
                                <span
                                  className="font-weight-bold"
                                  style={{ color: "black" }}
                                >
                                  {itemVariation.value}
                                </span>
                              </div>
                            </div>
                          );
                        }
                      );
                    }}
                  />
                  <Column
                    title="Quantity"
                    dataIndex="qty"
                    key="qty"
                    align={"center"}
                    width={"10%"}
                    render={(qty, record) => record && record.qty}
                  />
                  <Column
                    title="Unit Price"
                    dataIndex="productPrice"
                    key="unit_price"
                    align={"center"}
                    width={"10%"}
                    render={(productPrice) => productPrice.toFixed(2)}
                  />
                  <Column
                    title="Total"
                    dataIndex="price"
                    key="price"
                    align={"center"}
                    width={"10%"}
                    render={(price, record) => {
                      return (
                        <>{(record?.productPrice * record.qty).toFixed(2)}</>
                      );
                    }}
                  />
                </Table>
              </>
            );
          }}
        />

        <Column
          title="Shipping Details"
          dataIndex="shipping_type"
          key="shipping_type"
          width={"20%"}
          render={(shipping_type, record) => {
            return (
              <>
                <div className="warehouseName">
                  <span>Warehouse Name: </span>{" "}
                  <span className="text_color">
                    {record.shipping !== null
                      ? record.shipping.warehouse
                      : null}
                  </span>
                </div>
                <div className="category">
                  <span>Category: </span>
                  <span className="text_color" style={{ fontWeight: "bold" }}>
                    {record.shipping !== null ? record.shipping.category : null}
                  </span>
                </div>
                <div className="shippingAddress">
                  <span>Shipping Rate: </span>{" "}
                  <span className="text_color" style={{ fontWeight: "bold" }}>
                    {record?.shipping?.rate &&
                      calculateShippingPrice(
                        record?.shipping?.rate,
                        record?.shipping?.commission
                      ) +
                        " /- " +
                        record?.shipping?.unit_type}
                  </span>
                </div>
                <div className="tracking">
                  <span>Tracking: </span>
                  <span className="text_color">
                    {record?.shipmentProduct?.abroad_courier_tracking}
                  </span>
                </div>
              </>
            );
          }}
        />

        <Column
          title="Action"
          dataIndex="id"
          key="actions"
          align={"center"}
          render={(id, row) => {
            const productIssues = row.issues.find(
              (el) => el.status === "initial"
            );
            return (
              <>
                <Tooltip title="View Details">
                  <Button
                    onClick={() =>
                      Props.handlePageAction("product-detail", row)
                    }
                    shape="round"
                  >
                    Detail
                  </Button>
                </Tooltip>
                <Tooltip title="View Correlated Orders">
                  <Button
                    onClick={() => {
                      setShowOrdersModal(true);
                      setSelectedCorrelatedItemId(row);
                    }}
                    shape="round"
                    style={{ margin: "5px" }}
                  >
                    Orders
                  </Button>
                </Tooltip>
                {productIssues ? (
                  <Tooltip title="Take action">
                    <Button
                      onClick={() =>
                        Props.handlePageAction("product-action", row)
                      }
                      shape="round"
                      type={"primary"}
                      style={{ marginTop: 10 }}
                    >
                      Action
                    </Button>
                  </Tooltip>
                ) : (
                  <></>
                )}
              </>
            );
          }}
        />
      </Table>
    );
  };

  const getSelectedProducts = (selectedProducts) => {
    let products = [];

    for (const [, value] of Object.entries(selectedProducts)) {
      products.push(...value);
    }

    return products;
  };

  const finalSelectedProducts = getSelectedProducts(selectedProducts);

  return (
    <PageHeader ghost={true} title="Bangladesh To You">
      <Row>
        <Col span={24}>
          <Form
            form={form}
            labelCol={{ span: 12 }}
            layout="vertical"
            onFinish={handleFilterSubmit}
          >
            <Row gutter={24}>
              <Col md={12} lg={12} xl={8} xxl={4}>
                <Form.Item label="Website" name="website_id">
                  <Select
                    placeholder="Please select website"
                    onChange={(value) =>
                      handleFilterChange("website_id", value)
                    }
                    loading={isWebsiteFetching}
                  >
                    {websiteList &&
                      websiteList.map((d) => (
                        <Select.Option value={d.id} key={d.id}>
                          {d.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={12} lg={12} xl={8} xxl={4}>
                <Form.Item label="Order number" name="order_number">
                  <Input
                    placeholder="Enter order number"
                    onChange={(e) =>
                      handleFilterChange("order_number", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col md={12} lg={12} xl={8} xxl={4}>
                <Form.Item label="Product number" name="product_number">
                  <Input
                    placeholder="Enter product number"
                    onChange={(e) =>
                      handleFilterChange("product_number", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col md={12} lg={12} xl={8} xxl={4}>
                <Form.Item label="Payment status" name="payment_status">
                  <Select
                    placeholder="Select payment status"
                    onChange={(value) =>
                      handleFilterChange("payment_status", value)
                    }
                  >
                    <Select.Option value="paid">Paid</Select.Option>
                    <Select.Option value="unpaid">Unpaid</Select.Option>
                    <Select.Option value="partial-paid">
                      Partial paid
                    </Select.Option>
                    <Select.Option value="canceled">Canceled</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={4}>
                <Form.Item label="Product status" name="product_status">
                  <Select
                    placeholder="Select product status"
                    onChange={(value) =>
                      handleFilterChange("product_status", value)
                    }
                  >
                    <Select.Option value="arrived-at-warehouse">Arrived At Warehouse</Select.Option>
                    <Select.Option value="received-by-moveon">Received By MoveOn</Select.Option>
                    <Select.Option value="customs-released">Custom Released</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col md={12} lg={12} xl={8} xxl={5}>
                <Form.Item label="Your Order number" name="source_order_id">
                  <Input
                    placeholder="Enter sourch order id"
                    onChange={(e) =>
                      handleFilterChange("source_order_id", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Search
                  </Button>
                  <Button onClick={handleClear} style={{ marginLeft: "15px" }}>
                    Clear
                  </Button>
                  <Button
                    onClick={() => fetchOrders()}
                    type="primary"
                    danger
                    loading={bdToYouLoading}
                    style={{
                      float: "right",
                      marginRight: "3%",
                    }}
                  >
                    Reload
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          {finalSelectedProducts.length > 0 && (
            <Row style={{ marginBottom: 20 }}>
              <Col span={24}>
                <div style={{ float: "left" }}>
                  <Button
                    type="primary"
                    onClick={() => setNewDeliveryRequestPopup(true)}
                  >
                    New Delivery request ({finalSelectedProducts.length})
                  </Button>
                </div>
              </Col>
            </Row>
          )}
          <Table
            dataSource={bdToYouOrders.data}
            loading={bdToYouLoading}
            pagination={paginationConfig}
            rowKey="id"
            defaultExpandAllRows={true}
            expandedRowRender={renderNestedTable}
            scroll={{
              x: true,
            }}
          >
            <Column
              title="Order"
              dataIndex="order_number"
              key="order_number"
              align={"center"}
            />
            <Column
              title="Product Count"
              dataIndex="buyProducts"
              key="product_count"
              align={"center"}
              render={(buyProducts) => buyProducts.length}
            />
            <Column
              title="Buy Invoice Status"
              dataIndex="id"
              key="invoice_status"
              align={"center"}
              render={(id, record) => {
                return (
                  <>
                    {record?.buyInvoice && (
                      <div className="payment_status">
                        <StatusTag
                          slug={record?.buyInvoice[0]?.status}
                          text={record?.buyInvoice[0]?.status_label}
                        />
                      </div>
                    )}
                  </>
                );
              }}
            />
            <Column
              title="Shipment Invoice Status"
              dataIndex="id"
              key="shipment_invoice_status"
              align={"center"}
              render={(id, record) => {
                return (
                  <>
                    {
                      // record?.invoices && record?.invoices.length > 0 &&
                      <div className="payment_status">
                        <span>
                          <StatusTag
                            slug="invoice-payment-unpaid"
                            text={`${
                              record.invoices.filter(
                                (item) =>
                                  item.status.slug === "invoice-payment-unpaid"
                              ).length
                            } Unpaid`}
                          />
                        </span>
                        <br />
                        <span>
                          <StatusTag
                            slug="invoice-payment-partial-paid"
                            text={`${
                              record.invoices.filter(
                                (item) =>
                                  item.status.slug ===
                                  "invoice-payment-partial-paid"
                              ).length
                            }  Partial paid`}
                          />
                        </span>
                        <br />
                        <span>
                          <StatusTag
                            slug="invoice-payment-paid"
                            text={`${
                              record.invoices.filter(
                                (item) =>
                                  item.status.slug === "invoice-payment-paid"
                              ).length
                            }  Paid`}
                          />
                        </span>
                      </div>
                    }
                  </>
                );
              }}
            />
            <Column
              title="Created At"
              dataIndex="created_at"
              key="created-at"
              align={"center"}
              responsive={["xl"]}
              render={(created_at) => <DateTime date={created_at} />}
            />
            <Column
              title="Actions"
              dataIndex="id"
              key="actions"
              align={"center"}
              render={(id, row) => {
                return (
                  <>
                    <Tooltip title="View Address">
                      <Button
                        onClick={() => {
                          setShowAddressModal(true);
                          setSelectedOrder(row);
                        }}
                        shape="round"
                        style={{
                          marginRight: 5,
                        }}
                      >
                        Address
                      </Button>
                    </Tooltip>
                    <Tooltip title="View Invoice">
                      <Button
                        onClick={() =>
                          Props.handlePageAction("invoice", null, row)
                        }
                        shape="round"
                        type={"primary"}
                      >
                        Invoices
                      </Button>
                    </Tooltip>
                    {
                      row.invoices && row.invoices.length > 0 && (
                        <Tooltip title="View Shipment Invoice">
                          <Button
                            onClick={() =>
                              Props.handlePageAction("shipment-invoice", null, row)
                            }
                            shape="round"
                            type={"primary"}
                          >
                            Shipment Invoices
                          </Button>
                        </Tooltip>
                      )}
                  </>
                );
              }}
            />
          </Table>
        </Col>
        {selectedOrder && selectedOrder.addresses && (
          <AddressModal
            show={showAddressModal}
            onHide={() => setShowAddressModal(false)}
            addresses={selectedOrder.addresses}
          />
        )}
        {
          <OrdersModal
            show={showOrdersModal}
            onHide={() => setShowOrdersModal(false)}
            correlatedItemId={selectedCorrelatedItemId}
          />
        }
      </Row>

      {newDeliveryRequestPopup && (
        <NewDeliveryRequest
          show={newDeliveryRequestPopup}
          onHide={() => setNewDeliveryRequestPopup(false)}
          products={finalSelectedProducts}
        />
      )}
    </PageHeader>
  );
};

const DeliveryRequest = (Props) => {
  const [requests, setRequests] = useState([]);
  const INITIAL_FILTERS = {
    order_number: undefined,
    customer_id: undefined,
    date: undefined,
    product_number: undefined,
  };
  const [form] = Form.useForm();
  const [products, setProducts] = useState([]);
  const [logs, setLogs] = useState([]);
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [isLogLoading, setIsLogLoading] = useState(false);
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [showOrdersModal, setShowOrdersModal] = useState(false);
  const [selectedCorrelatedItemId, setSelectedCorrelatedItemId] =
    useState(null);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [selectedProductItemData, setSelectedProductItemData] = useState(null);

  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const fetchDeliveryRequests = React.useCallback((page, filters, per_page) => {
    setIsRequestLoading(true);
    getRequestedDelivery(page, filters, per_page)
      .then((res) => {
        if (res && res.data && res.data.data) {
          setRequests(res.data.data);
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 403) {
            message.error("The action is not authorized");
          } else {
            message.error(
              err.response?.data?.data?.data
                ? err.response.data.data.data.message
                : "Unknown error"
            );
          }
        } else {
          message.error("Unknown error");
        }
      })
      .finally(() => setIsRequestLoading(false));
  }, []);

  const fetchRequestedDeliveryProducts = React.useCallback((requestId) => {
    setIsProductsLoading(true);
    getRequestedDeliveryProducts(requestId)
      .then((res) => {
        if (res && res.data && res.data.data) {
          setProducts(res.data.data);
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 403) {
            message.error("The action is not authorized");
          } else {
            message.error(
              err.response?.data?.data?.data
                ? err.response.data.data.data.message
                : "Unknown error"
            );
          }
        } else {
          message.error("Unknown error");
        }
      })
      .finally(() => setIsProductsLoading(false));
  }, []);

  const fetchRequestedDeliveryLogs = React.useCallback((requestId) => {
    setIsLogLoading(true);
    getRequestedDeliveryLogs(requestId)
      .then((res) => {
        if (res && res.data && res.data.data) {
          setLogs(res.data.data);
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 403) {
            message.error("The action is not authorized");
          } else {
            message.error(
              err.response?.data?.data?.data
                ? err.response.data.data.data.message
                : "Unknown error"
            );
          }
        } else {
          message.error("Unknown error");
        }
      })
      .finally(() => setIsLogLoading(false));
  }, []);

  useEffect(() => {
    fetchDeliveryRequests(1, filters, 20);
  }, [fetchDeliveryRequests, filters]);

  const handleFilterChange = (field, value) => {
    let fieldValue = !value ? undefined : value;
    if (field === "date" && value[0] === "") {
      fieldValue = undefined;
    }
    setFilters({
      ...filters,
      [field]: fieldValue,
    });
  };

  const handleFilterSubmit = () => {
    let validFilters = [];
    for (let [key, value] of Object.entries(filters)) {
      if (value !== undefined) {
        validFilters.push({ key: key, value: value });
      }
    }
    insertUrlParams(validFilters);
    fetchDeliveryRequests(1, filters);
  };

  const handlePageChange = (page, pageSize) => {
    fetchDeliveryRequests(page, filters, pageSize);
  };

  const handleClear = () => {
    form.resetFields();
    setFilters(INITIAL_FILTERS);
  };

  const paginationConfig =
    requests && requests?.meta
      ? {
          total: requests.meta.total,
          current: requests.meta.current_page,
          onChange: handlePageChange,
          pageSize: requests.meta.per_page,
          showSizeChanger: false,
          position: ["none", "bottomLeft"],
        }
      : false;

  const renderNestedTable = (record, index) => {
    return (
      <RequestDetail
        currentStatus={record.status}
        products={products}
        requestStatusLogs={logs}
        isProductsLoading={isProductsLoading}
        isLogLoading={isLogLoading}
        handlePageAction={Props.handlePageAction}
        setShowOrdersModal={setShowOrdersModal}
        setSelectedCorrelatedItemId={setSelectedCorrelatedItemId}
        setSelectedProductItemData={setSelectedProductItemData}
        setShowRefundModal={setShowRefundModal}
      />
    );
  };

  const exportCSV = () => {

    const data = requests;

    // Define the columns to export
    const columns = [
      { key: "request_number", title: "Request" },
      { key: "deliver_to", title: "Deliver to" },
      { key: "courier", title: "Deliver by" },
      { key: "status", title: "Status" },
      { key: "created_at", title: "Created At" },
    ];

    // Map table data to CSV rows
    const csvRows = [];

    // Add header row
    csvRows.push(columns.map((col) => col.title).join(","));

    // Add data rows
    data.forEach((item) => {
      csvRows.push(
        columns
          .map((col) => {
            const value = item[col.key];
            // Handle nested values (e.g., courier.name)
            if (col.key === "courier") return value ? value.name : "";
            // Format the `created_at` field
            if (col.key === "created_at") {
              return value
                ? formateDateTime(new Date(value))
                : "";
            }
            return value !== null && value !== undefined ? value : "";
          })
          .join(",")
      );
    });

    // Combine rows into a single string
    const csvContent = csvRows.join("\n");

    // Create a Blob for the CSV file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a link element to trigger download
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = "Export_Delivery_Requests" + "_" + formatDateTime(new Date()) + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <PageHeader ghost={false} title={`Delivery Requests`}>
      <Row>
        <Col span={24}>
          <Form
            form={form}
            labelCol={{ span: 12 }}
            layout="vertical"
            onFinish={handleFilterSubmit}
          >
            <Row gutter={24}>
              <Col md={12} lg={12} xl={8} xxl={6}>
                <Form.Item label="Product number" name="product_number">
                  <Input
                    placeholder="Enter product number"
                    onChange={(e) =>
                      handleFilterChange("product_number", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>

              <Col md={12} lg={12} xl={8} xxl={6}>
                <Form.Item label="Order number" name="order_number">
                  <Input
                    placeholder="Enter order number"
                    onChange={(e) =>
                      handleFilterChange("order_number", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>

              <Col md={12} lg={12} xl={8} xxl={6}>
                <Form.Item label="Your Order number" name="source_order_id">
                  <Input
                    placeholder="Enter source order id"
                    onChange={(e) =>
                      handleFilterChange("source_order_id", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item>
                  <Button onClick={exportCSV}>
                    Export CSV
                  </Button>
                  <Button type="primary" htmlType="submit" style={{ marginLeft: "10px" }}>
                    Search
                  </Button>
                  <Button onClick={handleClear} style={{ marginLeft: "10px" }}>
                    Clear
                  </Button>
                  <Button
                    onClick={() => fetchDeliveryRequests()}
                    type="primary"
                    danger
                    loading={isRequestLoading}
                    style={{
                      float: "right",
                      marginRight: "3%",
                    }}
                  >
                    Reload
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Table
            dataSource={requests}
            loading={isRequestLoading}
            rowKey="id"
            defaultExpandAllRows={true}
            expandedRowRender={renderNestedTable}
            pagination={paginationConfig}
            onExpand={(expanded, record) => {
              fetchRequestedDeliveryProducts(record.id);
              fetchRequestedDeliveryLogs(record.id);
            }}
            scroll={{
              x: true,
            }}
          >
            <Column
              title="Request"
              dataIndex="request_number"
              key="code"
              align={"center"}
              render={(request_number) => {
                return " #" + request_number;
              }}
            />
            <Column
              title="Deliver to"
              dataIndex="deliver_to"
              key="deliver_to"
              align={"center"}
            />
            <Column
              title="Deliver by"
              dataIndex="courier"
              key="delivery_by"
              align={"center"}
              render={(courier) => {
                return courier ? <span>{courier.name}</span> : null;
              }}
            />
            <Column
              title="Status"
              dataIndex="status"
              key="status"
              align={"center"}
              style={{ width: 200 }}
              render={(status) =>
                status && <StatusTag slug={status} text={status} />
              }
            />
            <Column
              title="Created At"
              dataIndex="created_at"
              key="created-at"
              align={"center"}
              responsive={["xl"]}
              render={(created_at) => <DateTime date={created_at} />}
            />
          </Table>
        </Col>
        {
          <OrdersModal
            show={showOrdersModal}
            onHide={() => setShowOrdersModal(false)}
            correlatedItemId={selectedCorrelatedItemId}
          />
        }
        {
          <RefundForm
            show={showRefundModal}
            onHide={() => setShowRefundModal(false)}
            selectedProductItemData={selectedProductItemData}
          />
        }
      </Row>
    </PageHeader>
  );
};

export default MyParcel;
