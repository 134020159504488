import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Col,
  Form,
  Image,
  Input,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { DateTime, StatusTag } from "components";
import {
  calculateShippingPrice,
  getQueryParams,
  insertUrlParams,
  toastErrors,
  updateSize,
} from "utils/helpers";
import { useBuyForwardingParcel } from "context/buy-forwarding-context";
import { AddressModal, OrdersModal } from "../components";
import { getWebsites } from "utils/oauth-client";

const { Column } = Table;
const INITIAL_FILTERS = {
  website_id: undefined,
  order_number: undefined,
};

const MyOrder = (Props) => {
  const { myOrders, myOrdersLoading, fetchMyOrders } = useBuyForwardingParcel();
  const [form] = Form.useForm();
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showOrdersModal, setShowOrdersModal] = useState(false);
  const [selectedCorrelatedItemId, setSelectedCorrelatedItemId] =
    useState(null);
  const [websiteList, setWebsiteList] = useState([]);
  const [isWebsiteFetching, setIsWebsiteFetching] = useState(false);

  const fetchOrders = useCallback(() => {
    let queryParams = getQueryParams();
    let page = queryParams["page_index"]
      ? parseInt(queryParams["page_index"])
      : 1;
    let per_page = queryParams["per_page"]
      ? parseInt(queryParams["per_page"])
      : 10;
    fetchMyOrders(page, filters, per_page);
  }, [fetchMyOrders, filters]);

  const fetchWebsites = useCallback(() => {
    setIsWebsiteFetching(true);
    getWebsites()
      .then((res) => {
        setWebsiteList(res.data);
      })
      .catch(({ response }) => {
        if (response?.data) {
          toastErrors(response.data);
        }
      })
      .finally(() => setIsWebsiteFetching(false));
  }, []);

  useEffect(() => {
    fetchOrders();
    fetchWebsites();
  }, [fetchOrders, fetchWebsites]);

  const handleFilterChange = (field, value) => {
    let fieldValue = !value ? undefined : value;
    if (field === "date" && value[0] === "") {
      fieldValue = undefined;
    }
    setFilters({
      ...filters,
      [field]: fieldValue,
    });
  };

  const handleClear = () => {
    form.resetFields();
    setFilters(INITIAL_FILTERS);
  };

  const handleFilterSubmit = () => {
    let validFilters = [];
    for (let [key, value] of Object.entries(filters)) {
      if (value !== undefined) {
        validFilters.push({ key: key, value: value });
      }
    }
    insertUrlParams(validFilters);
    fetchMyOrders(1, filters);
  };

  const handlePageChange = (page, pageSize) => {
    insertUrlParams([
      {
        key: "page_index",
        value: page,
      },
    ]);
    fetchMyOrders(page, filters, pageSize);
  };

  const onShowSizeChange = (current, size) => {
    insertUrlParams([
      {
        key: "per_page",
        value: size,
      },
    ]);
    fetchMyOrders(current, filters, size);
  };

  const paginationConfig =
    myOrders && myOrders?.meta
      ? {
        total: myOrders.meta.total,
        current: myOrders.meta.current_page,
        onChange: handlePageChange,
        onShowSizeChange: onShowSizeChange,
        pageSize: myOrders.meta.per_page,
        showSizeChanger: true,
        position: ["none", "bottomLeft"],
      }
      : false;
  const renderNestedTable = (record, index) => {
    const items = record.buyProducts;
    const orderNumber = record.order_number;

    return (
      <Table
        dataSource={items}
        pagination={false}
        scroll={{
          x: 950,
        }}
      >
        <Column
          title="Image"
          dataIndex="image"
          key="image"
          align="center"
          width={"20%"}
          render={(image, record) =>
            record && record.image != null ? (
              <Image
                shape="square"
                width={100}
                src={
                  record?.store?.storeId === 1
                    ? updateSize(record.image, 100)
                    : updateSize(record.image, 800)
                }
              />
            ) : (
              <Avatar shape="square" size={64} src={""} />
            )
          }
        />

        <Column
          title="Product"
          dataIndex="product_number"
          key="product_number"
          width={"20%"}
          render={(product_number, record) => {
            return (
              <>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <div className="buy_title">
                      <a href={record.link} target="_blank" rel="noreferrer">
                        <Typography.Text
                          ellipsis={{ tooltip: record.title }}
                          style={{ width: "150px" }}
                        >
                          {record.title}
                        </Typography.Text>
                      </a>
                    </div>
                    <div className="productNumber">
                      <span>Product ID: {product_number}</span>
                    </div>
                    <div className="orderNumber">
                      <span>Order ID: {orderNumber}</span>
                    </div>
                    <div className="productStatus">
                      <span>Status : </span>{" "}
                      <StatusTag
                        slug={record.status}
                        text={record.status_label}
                      />
                    </div>
                    <div
                      className="invoiceStatus"
                      style={{ marginTop: "1%", marginBottom: "1%" }}
                    >
                      <span>Vendor : </span>
                      <Tag color="#87d068">{record?.vendor}</Tag>
                    </div>
                    <div className="note">
                      <span>Note: </span>
                      <span className="text_color">{record.note}</span>
                    </div>
                  </Col>
                </Row>
              </>
            );
          }}
        />

        <Column
          title="Product Meta"
          dataIndex="id"
          key="detail"
          style={{ width: "20%" }}
          align="center"
          render={(_, record) => {
            const totalQuantity = record?.itemMetas?.reduce((acc, value) => {
              if (value.qty === "") {
                value = 0;
              } else {
                value = parseInt(value.qty);
              }
              return acc + value;
            }, 0);

            const totalPriceBdt = record?.itemMetas?.reduce((acc, value) => {
              if (value.price === "") {
                value = 0;
              } else {
                value = parseFloat(value.productPrice * value.qty);
              }
              return acc + value;
            }, 0);

            return (
              <>
                <Table
                  style={{ padding: '10px', width: "100%" }}
                  dataSource={record.itemMetas}
                  bordered={false}
                  pagination={false}
                  scroll={{ y: 150, x: 650 }}
                  summary={() => {
                    let totalCharges = "";
                    if (record?.charges) {
                      totalCharges = record?.charges.reduce((acc, value) => {
                        return acc + value.amount;
                      }, 0);
                    }
                    return (
                      <>
                        {record?.charges?.map((metaCharge, index) => {
                          return (
                            <Table.Summary.Row key={index + "charges"}>
                              <Table.Summary.Cell colSpan={3}>
                                {metaCharge.type_label}
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align={"center"}>
                                <span>{metaCharge.amount.toFixed(2)}</span>
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          );
                        })}

                        <Table.Summary.Row>
                          <Table.Summary.Cell align={"center"}>
                            Total
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align={"center"}>
                            <span>{totalQuantity}</span>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align={"center"}>
                            &nbsp;
                          </Table.Summary.Cell>
                          <Table.Summary.Cell align={"center"}>
                            <span>
                              {totalPriceBdt
                                ? (
                                  (totalCharges ? totalCharges : null) +
                                  totalPriceBdt
                                ).toFixed(2)
                                : null}
                            </span>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                >
                  <Column
                    title="Meta"
                    dataIndex="meta"
                    key="meta"
                    align={"center"}
                    width={"25%"}
                    render={(_, record) => {
                      if (!record.meta || typeof record.meta !== "object") {
                        return <></>;
                      }
                      return record.meta.map(
                        (itemVariation, itemVariationID) => {
                          return (
                            <div
                              className="warehouse-address ml-lg-4"
                              key={`varItem-${itemVariationID}`}
                            >
                              <div style={{ whiteSpace: 'wrap' }}>
                                <span style={{ fontWeight: 600 }}>{itemVariation.title}</span>:&nbsp;
                                <span
                                  className="font-weight-bold"
                                  style={{ color: "black" }}
                                >
                                  {itemVariation.value}
                                </span>
                              </div>
                            </div>
                          );
                        }
                      );
                    }}
                  />
                  <Column
                    title="Quantity"
                    dataIndex="qty"
                    key="qty"
                    align={"center"}
                    width={"10%"}
                    render={(qty, record) => record && record.qty}
                  />

                  <Column
                    title="Unit Price"
                    dataIndex="productPrice"
                    key="unit_price"
                    align={"center"}
                    width={"10%"}
                    render={(productPrice) => {
                      return <>{productPrice.toFixed(2)}</>;
                    }}
                  />

                  <Column
                    title="Total"
                    dataIndex="productPrice"
                    key="price"
                    align={"center"}
                    width={"10%"}
                    render={(productPrice, record2) => {
                      return <>{(productPrice * record2.qty).toFixed(2)}</>;
                    }}
                  />
                </Table>
              </>
            );
          }}
        />

        <Column
          title="Shipping Details"
          dataIndex="shipping_type"
          key="shipping_type"
          width={"20%"}
          render={(shipping_type, record) => {
            return (
              <>
                <div className="warehouseName">
                  <span>Warehouse Name: </span>{" "}
                  <span className="text_color">
                    {record.shipping !== null
                      ? record.shipping.warehouse
                      : null}
                  </span>
                </div>
                <div className="category">
                  <span>Category: </span>
                  <span className="text_color" style={{ fontWeight: "bold" }}>
                    {record.shipping !== null ? record.shipping.category : null}
                  </span>
                </div>
                <div className="shippingAddress">
                  <span>Shipping Rate: </span>{" "}
                  <span className="text_color" style={{ fontWeight: "bold" }}>
                    {record?.shipping?.rate &&
                      calculateShippingPrice(
                        record?.shipping?.rate,
                        record?.shipping?.commission
                      ) +
                      " /- " +
                      record?.shipping?.unit_type}
                  </span>
                </div>
                <div className="tracking">
                  <span>Tracking: </span>
                  <a
                    href={`https://moveonbd.com/tracking?code=${record.product_number}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="text_color">{record?.product_number}</span>
                  </a>
                </div>
              </>
            );
          }}
        />

        <Column
          title="Action"
          dataIndex="id"
          key="actions"
          width={"20%"}
          align={"center"}
          render={(id, row) => {
            const productIssues = row.issues.find(
              (el) => el.status === "initial"
            );

            return (
              <>
                <Tooltip title="View Details">
                  <Button
                    onClick={() =>
                      Props.handlePageAction("product-detail", row)
                    }
                    shape="round"
                    style={{
                      marginBottom: 5,
                    }}
                  >
                    Detail
                  </Button>
                </Tooltip>
                <Tooltip title="View Correlated Orders">
                  <Button
                    onClick={() => {
                      setShowOrdersModal(true);
                      setSelectedCorrelatedItemId(row);
                    }}
                    shape="round"
                  >
                    Orders
                  </Button>
                </Tooltip>
                {productIssues ? (
                  <Tooltip title="Take action">
                    <Button
                      onClick={() =>
                        Props.handlePageAction("product-action", row)
                      }
                      shape="round"
                      type={"primary"}
                      style={{ marginTop: 10 }}
                    >
                      Action
                    </Button>
                  </Tooltip>
                ) : (
                  <></>
                )}
              </>
            );
          }}
        />
      </Table>
    );
  };

  return (
    <PageHeader ghost={true}>
      <Row>
        <Col span={24}>
          <Form
            form={form}
            labelCol={{ span: 12 }}
            layout="vertical"
            onFinish={handleFilterSubmit}
          >
            <Row gutter={24}>
              <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={4}>
                <Form.Item label="Website" name="website_id">
                  <Select
                    placeholder="Please select website"
                    onChange={(value) =>
                      handleFilterChange("website_id", value)
                    }
                    loading={isWebsiteFetching}
                  >
                    {websiteList &&
                      websiteList.map((d) => (
                        <Select.Option value={d.id} key={d.id}>
                          {d.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={4}>
                <Form.Item label="Order number" name="order_number">
                  <Input
                    placeholder="Enter order number"
                    onChange={(e) =>
                      handleFilterChange("order_number", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={4}>
                <Form.Item label="Product number" name="product_number">
                  <Input
                    placeholder="Enter product number"
                    onChange={(e) =>
                      handleFilterChange("product_number", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={4}>
                <Form.Item label="Payment status" name="payment_status">
                  <Select
                    placeholder="Select payment status"
                    onChange={(value) =>
                      handleFilterChange("payment_status", value)
                    }
                  >
                    <Select.Option value="paid">Paid</Select.Option>
                    <Select.Option value="unpaid">Unpaid</Select.Option>
                    <Select.Option value="partial-paid">
                      Partial paid
                    </Select.Option>
                    <Select.Option value="canceled">Canceled</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={4}>
                <Form.Item label="Product status" name="product_status">
                  <Select
                    placeholder="Select product status"
                    onChange={(value) =>
                      handleFilterChange("product_status", value)
                    }
                  >
                    <Select.Option value="buy-pending">Pending</Select.Option>
                    <Select.Option value="buy-approved">Approved</Select.Option>
                    <Select.Option value="buy-processing">Processing</Select.Option>
                    <Select.Option value="buy-purchased">Purchased</Select.Option>
                    <Select.Option value="buy-not-purchased">Not Purchased</Select.Option>
                    <Select.Option value="buy-canceled">Canceled</Select.Option>
                    <Select.Option value="handover-to-shipping">Handover To Shipping</Select.Option>
                    <Select.Option value="arrived-at-warehouse">Arrived At Warehouse</Select.Option>
                    <Select.Option value="preparing-for-transport">Preparing For Transport</Select.Option>
                    <Select.Option value="handover-to-airline">Handover To Airline</Select.Option>
                    <Select.Option value="arrived-at-destination-airport">Arrived At Destination Airport</Select.Option>
                    <Select.Option value="received-by-moveon">Received By MoveOn</Select.Option>
                    <Select.Option value="customs-released">Custom Released</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={5}>
                <Form.Item label="Your Order number" name="source_order_id">
                  <Input
                    placeholder="Enter sourch order id"
                    onChange={(e) =>
                      handleFilterChange("source_order_id", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="mobile-left-margin">
              <Col span={24}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Search
                  </Button>
                  <Button onClick={handleClear} style={{ marginLeft: "15px" }}>
                    Clear
                  </Button>
                  <Button
                    onClick={() => fetchOrders()}
                    type="primary"
                    danger
                    loading={myOrdersLoading}
                    style={{
                      float: "right",
                      marginRight: "3%",
                    }}
                  >
                    Reload
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Table
            dataSource={myOrders.data}
            loading={myOrdersLoading}
            pagination={paginationConfig}
            rowKey="id"
            expandedRowRender={renderNestedTable}
            defaultExpandAllRows={true}
            scroll={{
              x: 950,
            }}
            style={{width: '100%'}}
          >
            <Column
              title="Order"
              dataIndex="order_number"
              key="order_number"
              align={"center"}
            />
            <Column
              title="Product Count"
              dataIndex="buyProducts"
              key="product_count"
              align={"center"}
              render={(buyProducts) => buyProducts.length}
            />
            <Column
              title="Buy Invoice Status"
              dataIndex="id"
              key="invoice_status"
              align={"center"}
              render={(id, record) => {
                return (
                  <>
                    {record?.buyInvoice && (
                      <div className="payment_status">
                        <StatusTag
                          slug={record.buyInvoice[0]?.status}
                          text={record.buyInvoice[0]?.status_label}
                        />
                      </div>
                    )}
                  </>
                );
              }}
            />
            <Column
              title="Shipment Invoice Status"
              dataIndex="id"
              key="shipment_invoice_status"
              align={"center"}
              render={(id, record) => {
                return (
                  <>
                    {
                      // record?.invoices && record?.invoices.length > 0 &&
                      <div className="payment_status" style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>
                          <StatusTag
                            slug="invoice-payment-unpaid"
                            text={`${record.invoices.filter(
                              (item) =>
                                item.status.slug === "invoice-payment-unpaid"
                            ).length
                              } Unpaid`}
                          />
                        </span>
                        <br />
                        <span>
                          <StatusTag
                            slug="invoice-payment-partial-paid"
                            text={`${record.invoices.filter(
                              (item) =>
                                item.status.slug ===
                                "invoice-payment-partial-paid"
                            ).length
                              }  Partial paid`}
                          />
                        </span>
                        <br />
                        <span>
                          <StatusTag
                            slug="invoice-payment-paid"
                            text={`${record.invoices.filter(
                              (item) =>
                                item.status.slug === "invoice-payment-paid"
                            ).length
                              }  Paid`}
                          />
                        </span>
                      </div>
                    }
                  </>
                );
              }}
            />
            <Column
              title="Created At"
              dataIndex="created_at"
              key="created-at"
              align={"center"}
              responsive={["xl"]}
              render={(created_at) => <DateTime date={created_at} />}
            />
            <Column
              title="Actions"
              dataIndex="id"
              key="actions"
              align={"center"}
              render={(id, row) => {
                return (
                  <>
                    <Tooltip title="Address">
                      <Button
                        onClick={() => {
                          setShowAddressModal(true);
                          setSelectedOrder(row);
                        }}
                        shape="round"
                        style={{
                          marginRight: 5,
                        }}
                      >
                        Address
                      </Button>
                    </Tooltip>
                    <Tooltip title="View Invoice">
                      <Button
                        onClick={() =>
                          Props.handlePageAction("invoice", null, row)
                        }
                        shape="round"
                        type={"primary"}
                      >
                        Invoices
                      </Button>
                    </Tooltip>
                    {
                      row.invoices && row.invoices.length > 0 && (
                      <Tooltip title="View Shipment Invoice">
                      <Button
                        onClick={() =>
                          Props.handlePageAction("shipment-invoice", null, row)
                        }
                        shape="round"
                        type={"primary"}
                      >
                        Shipment Invoices
                      </Button>
                    </Tooltip>
                    )}
                  </>
                );
              }}
            />
          </Table>
        </Col>
        {selectedOrder && selectedOrder.addresses && (
          <AddressModal
            show={showAddressModal}
            onHide={() => setShowAddressModal(false)}
            addresses={selectedOrder.addresses}
          />
        )}

        {
          <OrdersModal
            show={showOrdersModal}
            onHide={() => setShowOrdersModal(false)}
            correlatedItemId={selectedCorrelatedItemId}
          />
        }
      </Row>
    </PageHeader>
  );
};

export default MyOrder;
